/* --------------------------------
   start screen
-------------------------------- */

#start-screen {
  .slogan-txt {
    font-size: 25px;
  }
}

.start-screen {
  position: relative;
  z-index: 1;
  background-color: $primary-color;

  &--full-height {
    .start-screen__content__item {
      min-height: 75vh;
    }
  }

  &__bg-container {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100% !important;
    }
  }

  &__bg {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__shapes {
    .img-shape {
      position: absolute;
      z-index: 0;
    }
  }

  &__content-container {
    position: relative;
    z-index: 2;
  }

  &__content__item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-top: 150px;
      height: 95vh !important;
    }
  }

  &__content-form {
    background: $white;
    padding: 40px 30px;
    font-size: 1.6rem;
  }

  .__site-name {
    line-height: 1.2;
    font-size: 2.5rem;
    font-weight: 800;
    font-style: italic;
    color: #333;
    letter-spacing: -0.05em;
  }

  .play-btn {
    display: inline-block;
    line-height: 1.2;
    font-size: 1.6rem;
    font-weight: 700;
    color: #333;
    text-decoration: none;

    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      color: $primary-color;
      border: 2px solid #eee;
      border-radius: 35%;
      @include transition(
                      background-color 0.3s ease-in-out,
                      color 0.3s ease-in-out
      );

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 5px;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 14px;
        border-color: transparent transparent transparent currentColor;
      }
    }

    &:hover {
      span {
        background-color: #2d3a49;
        color: $white;
      }
    }
  }
}

.start-screen--style-1 {
  .img-shape {
    &:nth-of-type(1) {
      top: 15%;
      left: 0;
    }

    &:nth-of-type(2) {
      max-height: 85%;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.start-screen--style-2 {
  .img-shape {
    &:nth-of-type(1) {
      max-height: 80%;
      top: 10%;
      left: 0;
    }

    &:nth-of-type(2) {
      max-width: 50%;
      min-width: 550px;
      max-height: 90%;
      top: 50%;
      left: 45vw;
      transform: translateY(-50%);
    }
  }
}

.start-screen--style-3 {
  .img-shape {
    &:nth-of-type(1) {
      bottom: 0;
      left: 0;
    }

    &:nth-of-type(2) {
      max-width: 60%;
      max-height: 90%;
      top: 17%;
      right: 0;
    }
  }
}

.start-screen--style-4 {
  @include media-breakpoint-up(lg) {
    //margin-bottom: 90px;
  }

  .container {
    margin-top: -100px;
   // margin-left: 45px !important;
  }

  @media (min-width: 1200px){

  .container {
   // margin-left: 343px !important;
  }
}
  .start-screen__content__item {
    min-height: 600px;
    height: 80vh;
  }

  .img-shape {
    &:nth-of-type(1) {
      max-width: 90%;
      bottom: -10%;
      left: 0;
      right: -5%;
      margin: 0 auto;
    }
  }
}

.start-screen--style-5 {

}

.start-screen--style-6 {
  .img-shape {
    &:nth-of-type(1) {
      max-width: 50%;
      min-width: 550px;
      max-height: 90%;
      top: 55%;
      left: 50vw;
      transform: translateY(-50%);
    }
  }

  .play-btn {
    span {
      color: $white;
    }

    &:hover {
      span {
        background-color: $white;
        color: #056eb9;
      }
    }
  }
}

.start-screen--style-7 {
  .img-shape {
    &:nth-of-type(1) {
      max-width: 50%;
      min-width: 550px;
      max-height: 90%;
      top: 55%;
      left: 50vw;
      transform: translateY(-50%);
    }
  }
}

.start-screen--style-8 {
  .img-shape {
    &:nth-of-type(1) {
      max-width: 40%;
      max-height: 90%;
      top: 55%;
      left: 8vw;
      transform: translateY(-50%);
    }
  }
}

.start-screen--style-9 {
  .start-screen__content__item {
    min-height: 450px;

    @include media-breakpoint-up(sm) {
      height: 85vh;
    }
  }
}

.start-screen--style-10 {
  .start-screen__content__item {
    min-height: 600px;
    height: 85vh;
  }

  .play-btn {
    span {
      width: 95px;
      height: 95px;
      color: $white;
      border-color: rgba($white, 0.38);
    }

    &:hover {
      span {
        background-color: $white;
        color: #056eb9;
      }
    }
  }
}

.start-screen--style-11 {
  .start-screen__content__item {
    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }
}

.start-screen--style-12 {
  .start-screen__content__item {
    min-height: 600px;
    height: 85vh;
  }

  .img-shape {
    &:nth-of-type(1) {
      min-width: 520px;
      max-width: 40%;
      max-height: 90%;
      top: 55%;
      left: 50vw;
      transform: translateY(-50%);
    }
  }
}

.start-screen--style-13 {
  .start-screen__content__item {
    // min-height: 600px;
  }

  .img-shape {
    &:nth-of-type(1) {
      min-width: 520px;
      max-width: 50%;
      max-height: 95%;
      top: 0;
      right: 0;
    }
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}

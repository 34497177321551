/* main colors */
/* main fonts */
/* main breakpoint */
/* display */
/* position */
/* float */
/* text align */
/* vertical align */
/*-- 
    Margin & Padding
-----------------------------------------*/
/*
	This mixin can be used to set the object-fit:
	@include object-fit(contain);

	or object-fit and object-position:
	@include object-fit(cover, top);
*/
/* .box { @include transition(width, height 0.3s ease-in-out); } */
/* .box { @include border-radius(10px); } */
/* CSS3 calc() function to perform calculations */
@import url(vendors/normalize.css);
@import url(vendors/fontello-codes.css);
* {
  padding: 0;
  margin: 0; }
  *, *::before, *::after {
    box-sizing: inherit; }

.header-progress-bar {
  display: none !important; }

.ico-checked-center {
  float: none !important;
  margin-right: 15px !important; }

.item-title {
  color: #fff; }
  .item-title .small {
    color: #ccc;
    font-size: 1.4rem;
    font-weight: 400; }

.submenu a:hover {
  color: #fff !important; }

.configurator {
  background: #1d2124;
  border-radius: 25px;
  padding: 40px;
  color: #fff; }
  .configurator .configurator-pricing {
    font-size: 3rem !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important; }
    .configurator .configurator-pricing span {
      font-size: 27px !important;
      letter-spacing: normal !important; }
  .configurator .accordion-item-first {
    border-top: none !important; }
  .configurator .accordion-content__inner p {
    margin-bottom: 20px; }
  .configurator .btn {
    font-size: 1.8rem !important;
    text-transform: none !important; }
  .configurator .accordion-toggler i {
    left: 0;
    right: unset; }
  @media (min-width: 576px) {
    .configurator .accordion-toggler {
      padding: 30px 0px 30px 45px !important; } }

.is-sticky .text-shadow {
  text-shadow: none !important; }

.is-expanded .text-shadow {
  text-shadow: none !important; }

.title-tag {
  font-size: 20px;
  font-weight: 700;
  color: #14212b;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  line-height: 1.6 !important; }

.title-tag-lr {
  font-size: 20px;
  font-weight: 700;
  color: #14212b;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  padding-right: 45px;
  line-height: 1.6 !important; }

.font-small {
  font-size: 12px; }

.__title {
  margin-top: 0px !important; }

.title-tag::before {
  display: block;
  position: absolute;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: #14212b;
  content: "";
  top: 16px;
  transform: translateY(-50%); }

.title-tag-white::before {
  background-color: #fff !important; }

.title-tag-white::after {
  background-color: #fff !important; }

.nav-link-small {
  padding: 0px !important; }

.nav-link-small button {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-height: 45px !important; }

.title-tag-lr::before {
  display: block;
  position: absolute;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: #14212b;
  content: "";
  top: 16px;
  transform: translateY(-50%); }

.title-tag-lr::after {
  display: block;
  left: auto;
  right: 0;
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #14212b;
  content: "";
  top: 50%;
  transform: translateY(-50%); }

.text-shadow {
  text-shadow: 3px 3px 7px black; }

.customer-title {
  font-size: 25px; }

#landing-page #brands-small {
  padding-bottom: 0px !important; }

#start-screen .nav-link {
  padding: 0px; }

.main-title {
  position: absolute;
  margin: 0;
  padding: 0;
  color: #f9f1e9;
  text-align: center;
  top: -16%;
  left: 0; }

.slick-track {
  padding-top: 12px;
  padding-bottom: 12px; }

.background-overlay {
  background: rgba(32, 33, 36, 0.66);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; }

.img-rounded {
  border-radius: 15px; }

.section-bg-light-dark {
  background-color: #f3f3f3; }

.section-special {
  background-image: linear-gradient(#f3f3f3 50%, #fff 50%) !important;
  background-size: calc(1 * (100% / 2)) 100%; }

.section-special .__inner::before {
  left: 0px;
  right: 0px;
  bottom: -15px;
  top: 20px; }

.section-special .__inner::before {
  position: absolute;
  background-color: #14212b;
  z-index: -2;
  content: "";
  opacity: 0.07;
  border-radius: 20px; }

.section-special .__inner::after {
  left: -15px;
  right: -15px;
  bottom: -30px;
  top: 40px; }

.section-special .__inner::after {
  position: absolute;
  background-color: #14212b;
  z-index: -2;
  content: "";
  opacity: 0.07;
  border-radius: 20px; }

.pricing-btn {
  color: #2a2c2f !important; }

.pricing-btn:hover {
  color: #fff !important; }

.start-screen__content-container h2 {
  font-size: 60px; }

#large-header {
  height: 75vh !important; }

.large-header {
  position: relative;
  width: 100%;
  background: #333;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1; }

html {
  font-size: 10px;
  text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box; }

body {
  line-height: 1.6;
  font-size: 1.9rem;
  font-family: -apple-system, BlinkMacSystemFont, "Nunito Sans", sans-serif;
  font-weight: 400;
  color: #2a2c2f;
  background-color: #fff; }

@media (min-width: 1100px) {
  .easy-digitization {
    width: 30% !important; } }

@media (min-width: 800px) {
  .easy-digitization {
    width: 40% !important; } }

@media (min-width: 580px) {
  .easy-digitization {
    width: 50% !important; } }

.easy-digitization {
  width: 60% !important; }

ul {
  list-style: none;
  list-style-image: none;
  margin: 0; }

#career-offer .__title {
  color: #1d2124 !important; }

.rounded-img {
  border-radius: 100%;
  border: 2px solid #1d2124; }

.font-size-16 {
  font-size: 1.6rem !important; }

.container-start-screen {
  padding-top: 0 !important;
  vertical-align: middle;
  margin: auto; }

.non-flex {
  display: -webkit-box !important; }

.align-top {
  vertical-align: top !important;
  display: inline-block !important; }

.medium {
  width: 50%; }

.mt-20 {
  margin-top: 20px; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/* --------------------------------
   fontello
-------------------------------- */
@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello/fontello.eot?83032100");
  src: url("../fonts/fontello/fontello.eot?83032100#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello.woff2?83032100") format("woff2"), url("../fonts/fontello/fontello.woff?83032100") format("woff"), url("../fonts/fontello/fontello.ttf?83032100") format("truetype"), url("../fonts/fontello/fontello.svg?83032100#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello/fontello.svg?83032100#fontello') format('svg');
  }
}
*/
[class^="fontello-"]:before,
[class*=" fontello-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: auto;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.2;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  color: #1d2124;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: color 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
      transition: none; } }
  h1:first-child, .h1:first-child,
  h2:first-child, .h2:first-child,
  h3:first-child, .h3:first-child,
  h4:first-child, .h4:first-child,
  h5:first-child, .h5:first-child,
  h6:first-child, .h6:first-child {
    margin-top: 0; }
  h1:last-child, .h1:last-child,
  h2:last-child, .h2:last-child,
  h3:last-child, .h3:last-child,
  h4:last-child, .h4:last-child,
  h5:last-child, .h5:last-child,
  h6:last-child, .h6:last-child {
    margin-bottom: 0; }
  h1 a, .h1 a,
  h2 a, .h2 a,
  h3 a, .h3 a,
  h4 a, .h4 a,
  h5 a, .h5 a,
  h6 a, .h6 a {
    color: inherit;
    text-decoration: none; }
  h1 span, .h1 span,
  h2 span, .h2 span,
  h3 span, .h3 span,
  h4 span, .h4 span,
  h5 span, .h5 span,
  h6 span, .h6 span {
    font-weight: 300; }

h1, .h1,
h2, .h2,
h3, .h3 {
  letter-spacing: -0.05em; }

h1, .h1 {
  font-size: 2rem; }
  @media (min-width: 576px) {
    h1, .h1 {
      font-size: 3.5rem; } }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 5rem; } }

h2, .h2 {
  font-size: 3.5rem; }
  @media (min-width: 576px) {
    h2, .h2 {
      font-size: 4rem; } }

h3, .h3 {
  font-size: 3rem; }

h4, .h4 {
  font-size: 2.2rem; }

h5, .h5 {
  font-size: 1.6rem; }

h6, .h6 {
  font-size: 1.3rem;
  text-transform: uppercase; }

main ::selection {
  background-color: #2368fb;
  color: #fff; }

main ::-moz-selection {
  background-color: #2368fb;
  color: #fff; }

main p {
  margin-top: 20px;
  margin-bottom: 20px; }
  main p:first-child {
    margin-top: 0 !important; }
  main p:last-child {
    margin-bottom: 0 !important; }

a {
  color: #2368fb;
  text-decoration: underline;
  outline: none;
  transition: color 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    a {
      transition: none; } }
  a:hover, a:focus {
    color: #2368fb;
    text-decoration: none; }

.section-heading {
  line-height: 1.4;
  font-size: 1.9rem;
  color: #2a2c2f; }
  .section-heading .__title:first-child {
    margin-top: -0.2em; }
  .section-heading .__subtitle {
    font-family: "Nunito Sans", sans-serif;
    color: #2368fb; }
  .section-heading--left {
    text-align: left; }
  .section-heading--center {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .section-heading--right {
    text-align: right; }
  .section-heading--white {
    color: #fff; }
    .section-heading--white .__title,
    .section-heading--white .__subtitle {
      color: inherit; }

.text-grading {
  color: #2368fb; }

/* --------------------------------
   header
-------------------------------- */
.front-title {
  font-size: 5.6rem !important; }

.front-text {
  font-size: 2.2rem !important; }

.submenu-title {
  font-size: 16px; }

.submenu-title:after {
  content: '.';
  display: block;
  height: 4px;
  width: 50%;
  text-indent: -9999px;
  border-top: 2px solid #2368fb;
  margin-top: 3px; }

#start-screen {
  background: url("../img/banner/banner-1.jpg");
  background-size: cover; }

.column-nav-20 {
  float: left;
  width: 20%; }
  .column-nav-20 li {
    padding: 5px 33px !important; }
  .column-nav-20 .small {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ccc; }

.column-nav-33 {
  float: left;
  width: 33%; }
  .column-nav-33 li {
    padding: 5px 33px !important; }
  .column-nav-33 .small {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ccc; }

.column-nav-50 {
  float: left;
  width: 50%; }
  .column-nav-50 li {
    padding: 5px 33px !important; }
  .column-nav-50 .small {
    font-size: 1.4rem;
    font-weight: 400;
    color: #ccc; }

#header-img {
  background: linear-gradient(to bottom, #2368fb 0%, #2368fb 50%, #2368fb 50%, #f3f3f3 50%, #f3f3f3 100%);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important; }
  #header-img .col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  #header-img .easy-digitization {
    border-radius: 50%;
    border: 5px solid #2368fb;
    width: 100% !important; }

.sticky-logo {
  display: none; }

.is-expanded .sticky-logo {
  display: block; }

.is-expanded .large-logo {
  display: none; }

.is-sticky .item-title {
  color: #000 !important; }

.is-sticky .top-bar__logo .sticky-logo {
  display: block; }

.is-sticky .top-bar__logo .large-logo {
  display: none; }

.top-bar--light {
  color: #fff; }
  .top-bar--light.is-sticky {
    background-color: #fff;
    color: #1d2124 !important;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); }
  .top-bar--light.is-expanded .top-bar__collapse {
    background-color: #fff; }
  .top-bar--light.is-expanded .top-bar__navigation {
    border-bottom: 1px solid rgba(242, 242, 242, 0.25); }
    .top-bar--light.is-expanded .top-bar__navigation li {
      border-top: 1px solid #f3f3f3; }
  .top-bar--light .top-bar__navigation a:after {
    background-color: currentColor; }
  @media (min-width: 1200px) {
    .top-bar--light .top-bar__navigation a.active {
      color: inherit; } }
  .top-bar--light .top-bar__auth-btns a:after {
    background-color: currentColor; }

.top-bar--dark {
  color: #333; }
  .top-bar--dark.is-sticky {
    background-color: #fff;
    box-shadow: 0px 1px 5px 0px rgba(36, 36, 36, 0.12); }
  .top-bar--dark.is-expanded .top-bar__collapse {
    background-color: #fff;
    color: #1d2124; }
  .top-bar--dark.is-expanded .top-bar__navigation {
    border-bottom: 1px solid #f2f2f2; }
    .top-bar--dark.is-expanded .top-bar__navigation li {
      border-top: 1px solid #f2f2f2; }
  .top-bar--dark .top-bar__navigation a:after {
    background-color: #4a60dd; }
  @media (min-width: 1200px) {
    .top-bar--dark .top-bar__navigation a.active {
      color: #a3a3a3; } }
  .top-bar--dark .top-bar__auth-btns a:after {
    background-color: #4a60dd; }

.top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px 0;
  font-size: 1.6rem;
  font-weight: 700;
  transition: top 0.3s;
  z-index: 5; }
  @media screen and (prefers-reduced-motion: reduce) {
    .top-bar {
      transition: none; } }
  .top-bar.in {
    -webkit-animation-name: TopBarSlideInDown;
    animation-name: TopBarSlideInDown;
    animation-duration: 300ms; }

@-webkit-keyframes TopBarSlideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes TopBarSlideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
  .top-bar.out {
    -webkit-animation-name: TopBarSlideOutUp;
    animation-name: TopBarSlideOutUp;
    animation-duration: 200ms; }

@-webkit-keyframes TopBarSlideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes TopBarSlideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }
  .top-bar.is-sticky {
    position: unset;
    top: 0;
    animation-fill-mode: both; }
  .top-bar.is-expanded .top-bar__collapse {
    color: #1d2124;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    border-top: 150px solid transparent;
    border-bottom: 40px solid transparent;
    overflow-y: auto; }
  .top-bar.is-expanded .top-bar__navigation {
    margin-bottom: 30px; }
    .top-bar.is-expanded .top-bar__navigation:last-child {
      margin-bottom: 0; }
    .top-bar.is-expanded .top-bar__navigation li {
      padding-left: 15px;
      padding-right: 15px; }
      .top-bar.is-expanded .top-bar__navigation li.has-submenu:before {
        margin-top: 20px; }
    .top-bar.is-expanded .top-bar__navigation a:not(.custom-btn) {
      display: block;
      padding-top: 17px;
      padding-bottom: 17px; }
  .top-bar.is-expanded .top-bar__action {
    padding: 0 15px; }
  .top-bar a:not(.custom-btn) {
    color: inherit;
    text-decoration: none; }
  .top-bar__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px; }
  .top-bar__logo {
    position: relative;
    z-index: 6; }
  .top-bar__navigation-toggler {
    position: absolute;
    top: 0px;
    right: 15px;
    padding: 50px 50px;
    z-index: 6; }
    .top-bar__navigation-toggler span {
      position: relative;
      display: block;
      height: 3px;
      width: 40px; }
      .top-bar__navigation-toggler span:before, .top-bar__navigation-toggler span:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%; }
      .top-bar__navigation-toggler span:before {
        top: -8px; }
      .top-bar__navigation-toggler span:after {
        top: 8px; }
    .top-bar__navigation-toggler span,
    .top-bar__navigation-toggler span:before,
    .top-bar__navigation-toggler span:after {
      background-color: currentColor;
      transition: all 400ms ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        .top-bar__navigation-toggler span,
        .top-bar__navigation-toggler span:before,
        .top-bar__navigation-toggler span:after {
          transition: none; } }
    .top-bar__navigation-toggler.is-active {
      color: #1d2124 !important; }
      .top-bar__navigation-toggler.is-active span {
        background-color: transparent !important; }
        .top-bar__navigation-toggler.is-active span:before, .top-bar__navigation-toggler.is-active span:after {
          top: 0;
          transform-origin: 50% 50%; }
        .top-bar__navigation-toggler.is-active span:before {
          transform: rotate(225deg); }
        .top-bar__navigation-toggler.is-active span:after {
          transform: rotate(-225deg); }
  .top-bar__collapse {
    height: 0;
    overflow-y: hidden; }
  .top-bar__navigation {
    position: relative;
    text-align: left; }
    .top-bar__navigation ul {
      line-height: 0;
      font-size: 0;
      letter-spacing: -1px; }
      .top-bar__navigation ul:before, .top-bar__navigation ul:after {
        content: "";
        display: table;
        clear: both; }
    .top-bar__navigation li {
      position: relative;
      font-size: 1.6rem;
      line-height: 1;
      letter-spacing: 0;
      white-space: normal; }
      .top-bar__navigation li:first-child {
        margin-top: 0 !important;
        margin-left: 0 !important; }
      .top-bar__navigation li.has-submenu:before {
        content: "";
        float: right;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        border-color: currentColor;
        margin-left: 13px;
        margin-top: 3px;
        transform: rotate(45deg);
        transform-origin: center;
        transition: border-color 0.3s ease-in-out; }
        @media screen and (prefers-reduced-motion: reduce) {
          .top-bar__navigation li.has-submenu:before {
            transition: none; } }
    .top-bar__navigation a:not(.custom-btn) {
      position: relative;
      display: inline-block;
      padding: 0;
      line-height: inherit;
      font-size: inherit;
      font-family: inherit;
      transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        .top-bar__navigation a:not(.custom-btn) {
          transition: none; } }
      .top-bar__navigation a:not(.custom-btn):before, .top-bar__navigation a:not(.custom-btn):after {
        pointer-events: none; }
    .top-bar__navigation a.active {
      color: #2368fb; }
    .top-bar__navigation .submenu {
      display: none; }
  .top-bar__action {
    margin-left: auto; }
  .top-bar__choose-lang {
    position: relative;
    display: inline-block;
    vertical-align: middle; }
    .top-bar__choose-lang .current-lang {
      display: table;
      min-width: 70px;
      line-height: 1;
      cursor: pointer; }
      .top-bar__choose-lang .current-lang > * {
        display: table-cell;
        vertical-align: middle; }
      .top-bar__choose-lang .current-lang span {
        padding-left: 10px; }
        .top-bar__choose-lang .current-lang span:after {
          content: "";
          float: right;
          width: 6px;
          height: 6px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          border-color: currentColor;
          margin-left: 8px;
          margin-top: 4px;
          transform: rotate(45deg);
          transform-origin: center;
          transition: border-color 0.3s ease-in-out; }
          @media screen and (prefers-reduced-motion: reduce) {
            .top-bar__choose-lang .current-lang span:after {
              transition: none; } }
    .top-bar__choose-lang .list-wrap {
      display: none; }
      .top-bar__choose-lang .list-wrap ul {
        margin-top: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        line-height: 1;
        background-color: #343a3f; }
      .top-bar__choose-lang .list-wrap li {
        position: relative;
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        line-height: 1.2;
        font-size: 1.6rem;
        font-weight: 400;
        color: #fff;
        cursor: pointer; }
        .top-bar__choose-lang .list-wrap li:first-child {
          margin-top: 0; }
        .top-bar__choose-lang .list-wrap li span {
          position: relative;
          display: inline-block;
          vertical-align: top; }
          .top-bar__choose-lang .list-wrap li span:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 2px;
            margin-top: 3px;
            opacity: 0;
            visibility: hidden;
            transition: left 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; }
            @media screen and (prefers-reduced-motion: reduce) {
              .top-bar__choose-lang .list-wrap li span:after {
                transition: none; } }
        .top-bar__choose-lang .list-wrap li.is-active {
          color: #8d9296;
          cursor: default; }
          .top-bar__choose-lang .list-wrap li.is-active span:after {
            left: 0;
            width: 100%;
            opacity: 1;
            visibility: visible;
            background-color: #2158a6; }
    .top-bar__choose-lang img {
      display: inline-block;
      width: 25px;
      height: 25px; }
  .top-bar__auth-btns {
    margin-top: 20px;
    line-height: 1; }
    .top-bar__auth-btns:first-child {
      margin-top: 0; }
    .top-bar__auth-btns a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px; }
      .top-bar__auth-btns a:first-child {
        margin-left: 0; }
      .top-bar__auth-btns a:not(.custom-btn):after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 2px;
        margin-top: 9px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; }
        @media screen and (prefers-reduced-motion: reduce) {
          .top-bar__auth-btns a:not(.custom-btn):after {
            transition: none; } }
      .top-bar__auth-btns a:not(.custom-btn):hover:after {
        left: 0;
        width: 100%;
        opacity: 1;
        visibility: visible; }
  .top-bar__side-menu-button {
    display: none;
    vertical-align: middle;
    margin-left: 20px;
    padding: 5px 0;
    cursor: pointer; }
    .top-bar__side-menu-button .line {
      display: block;
      width: 27px;
      border-top: 2px solid currentColor;
      margin-top: 5px;
      margin-left: auto;
      transition: width 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        .top-bar__side-menu-button .line {
          transition: none; } }
      .top-bar__side-menu-button .line:first-child {
        margin-top: 0; }
      .top-bar__side-menu-button .line:last-child {
        width: 18px; }
    .top-bar__side-menu-button:hover .line:last-child, .top-bar__side-menu-button:focus .line:last-child {
      width: 27px; }

@media (min-width: 1200px) {
  .top-bar {
    top: 15px;
    padding: 18px 0; }
    .top-bar__navigation-toggler {
      display: none; }
    .top-bar__navigation {
      margin-left: 40px; }
      .top-bar__navigation li:hover > a:after, .top-bar__navigation a.active:after {
        left: 0;
        width: 100%;
        opacity: 1;
        visibility: visible; }
      .top-bar__navigation li {
        display: inline-block;
        vertical-align: middle;
        margin-left: 30px; }
        .top-bar__navigation li.has-submenu:hover > .submenu {
          margin-top: 20px;
          opacity: 1;
          visibility: visible; }
      .top-bar__navigation a:after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 2px;
        margin-top: 9px;
        opacity: 0;
        visibility: hidden;
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; } }
      @media screen and (min-width: 1200px) and (prefers-reduced-motion: reduce) {
        .top-bar__navigation a:after {
          transition: none; } }

@media (min-width: 1200px) {
      .top-bar__navigation .submenu .submenu-icon {
        margin-top: 1.2rem !important; }
      .top-bar__navigation .submenu .submenu-title {
        font-size: 1.6rem !important; }
      .top-bar__navigation .submenu .item-title {
        font-size: 1.6rem !important; }
      .top-bar__navigation .submenu-20 {
        min-width: 950px !important;
        margin-right: -475px !important; }
      .top-bar__navigation .submenu-33 {
        min-width: 750px !important;
        margin-right: -375px !important; }
      .top-bar__navigation .submenu-50 {
        min-width: 490px !important;
        margin-right: -245px !important; }
      .top-bar__navigation .submenu {
        border-radius: 10px;
        display: block;
        border-top-width: 0;
        position: absolute;
        top: 100%;
        right: 50%;
        min-width: 550px;
        margin-top: 40px;
        margin-right: -275px;
        padding: 35px 0 40px;
        background-color: #1d2124;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        z-index: 3;
        transition: opacity 0.2s ease-in-out, margin-top 0.3s ease-in-out, visibility 0.2s ease-in-out; } }
      @media screen and (min-width: 1200px) and (prefers-reduced-motion: reduce) {
        .top-bar__navigation .submenu {
          transition: none; } }

@media (min-width: 1200px) {
        .top-bar__navigation .submenu:before {
          content: "";
          display: block;
          margin-top: -55px;
          height: 55px; }
        .top-bar__navigation .submenu li {
          display: list-item;
          margin-top: 5px;
          margin-left: 0;
          padding: 5px 50px;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.4; }
          .top-bar__navigation .submenu li.active > a, .top-bar__navigation .submenu li:hover > a {
            color: #8d9296; }
          .top-bar__navigation .submenu li.has-submenu:hover:before {
            border-color: #8d9296; }
          .top-bar__navigation .submenu li.has-submenu:hover .submenu {
            margin-top: 0; }
        .top-bar__navigation .submenu a:after {
          margin-top: 0;
          background-color: #0383c3 !important; }
        .top-bar__navigation .submenu a:hover, .top-bar__navigation .submenu a:focus {
          color: #8d9296; }
        .top-bar__navigation .submenu .submenu {
          top: 0;
          right: 100%;
          margin-right: 0;
          background-color: #27343d; }
          .top-bar__navigation .submenu .submenu:before {
            content: none; }
    .top-bar__collapse {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-basis: 0;
      height: 40px;
      overflow: visible; }
    .top-bar__choose-lang .list-wrap {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 160px;
      margin-left: -80px; }
      .top-bar__choose-lang .list-wrap li {
        margin-left: 45px;
        margin-right: 45px; }
    .top-bar__auth-btns {
      margin-top: 0;
      margin-left: 25px; }
    .top-bar__side-menu-button {
      display: inline-block; } }

@media only screen and (min-width: 1400px) {
  .top-bar__navigation {
    margin-left: 100px; }
    .top-bar__navigation li {
      margin-left: 40px; }
  .top-bar__auth-btns {
    margin-left: 50px; }
    .top-bar__auth-btns a {
      margin-left: 30px; }
  .top-bar__side-menu-button {
    margin-left: 35px; } }

.top-bar.is-sticky {
  padding-bottom: 20px; }
  .top-bar.is-sticky .top-bar__logo {
    width: 95px; }

.container-start-screen {
  padding-top: 150px; }

@media (min-width: 1200px) {
  .top-bar__inner {
    max-width: 1170px; } }

@media (min-width: 992px) {
  .top-bar__inner {
    max-width: 960px; } }

@media (min-width: 768px) {
  .top-bar__inner {
    max-width: 720px; } }

@media (min-width: 576px) {
  .top-bar__inner {
    max-width: 540px; } }

/* --------------------------------
   start screen
-------------------------------- */
#start-screen .slogan-txt {
  font-size: 25px; }

.start-screen {
  position: relative;
  z-index: 1;
  background-color: #2368fb; }
  .start-screen--full-height .start-screen__content__item {
    min-height: 75vh; }
  .start-screen__bg-container {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }
    .start-screen__bg-container .slick-list,
    .start-screen__bg-container .slick-track,
    .start-screen__bg-container .slick-slide {
      height: 100% !important; }
  .start-screen__bg {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover; }
  .start-screen__shapes .img-shape {
    position: absolute;
    z-index: 0; }
  .start-screen__content-container {
    position: relative;
    z-index: 2; }
  .start-screen__content__item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 50px; }
    @media (min-width: 768px) {
      .start-screen__content__item {
        padding-top: 150px;
        height: 95vh !important; } }
  .start-screen__content-form {
    background: #fff;
    padding: 40px 30px;
    font-size: 1.6rem; }
  .start-screen .__site-name {
    line-height: 1.2;
    font-size: 2.5rem;
    font-weight: 800;
    font-style: italic;
    color: #333;
    letter-spacing: -0.05em; }
  .start-screen .play-btn {
    display: inline-block;
    line-height: 1.2;
    font-size: 1.6rem;
    font-weight: 700;
    color: #333;
    text-decoration: none; }
    .start-screen .play-btn span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      color: #2368fb;
      border: 2px solid #eee;
      border-radius: 35%;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        .start-screen .play-btn span {
          transition: none; } }
      .start-screen .play-btn span:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 5px;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 14px;
        border-color: transparent transparent transparent currentColor; }
    .start-screen .play-btn:hover span {
      background-color: #2d3a49;
      color: #fff; }

.start-screen--style-1 .img-shape:nth-of-type(1) {
  top: 15%;
  left: 0; }

.start-screen--style-1 .img-shape:nth-of-type(2) {
  max-height: 85%;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }

.start-screen--style-2 .img-shape:nth-of-type(1) {
  max-height: 80%;
  top: 10%;
  left: 0; }

.start-screen--style-2 .img-shape:nth-of-type(2) {
  max-width: 50%;
  min-width: 550px;
  max-height: 90%;
  top: 50%;
  left: 45vw;
  transform: translateY(-50%); }

.start-screen--style-3 .img-shape:nth-of-type(1) {
  bottom: 0;
  left: 0; }

.start-screen--style-3 .img-shape:nth-of-type(2) {
  max-width: 60%;
  max-height: 90%;
  top: 17%;
  right: 0; }

.start-screen--style-4 .container {
  margin-top: -100px; }

.start-screen--style-4 .start-screen__content__item {
  min-height: 600px;
  height: 80vh; }

.start-screen--style-4 .img-shape:nth-of-type(1) {
  max-width: 90%;
  bottom: -10%;
  left: 0;
  right: -5%;
  margin: 0 auto; }

.start-screen--style-6 .img-shape:nth-of-type(1) {
  max-width: 50%;
  min-width: 550px;
  max-height: 90%;
  top: 55%;
  left: 50vw;
  transform: translateY(-50%); }

.start-screen--style-6 .play-btn span {
  color: #fff; }

.start-screen--style-6 .play-btn:hover span {
  background-color: #fff;
  color: #056eb9; }

.start-screen--style-7 .img-shape:nth-of-type(1) {
  max-width: 50%;
  min-width: 550px;
  max-height: 90%;
  top: 55%;
  left: 50vw;
  transform: translateY(-50%); }

.start-screen--style-8 .img-shape:nth-of-type(1) {
  max-width: 40%;
  max-height: 90%;
  top: 55%;
  left: 8vw;
  transform: translateY(-50%); }

.start-screen--style-9 .start-screen__content__item {
  min-height: 450px; }
  @media (min-width: 576px) {
    .start-screen--style-9 .start-screen__content__item {
      height: 85vh; } }

.start-screen--style-10 .start-screen__content__item {
  min-height: 600px;
  height: 85vh; }

.start-screen--style-10 .play-btn span {
  width: 95px;
  height: 95px;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.38); }

.start-screen--style-10 .play-btn:hover span {
  background-color: #fff;
  color: #056eb9; }

@media (max-width: 767.98px) {
  .start-screen--style-11 .start-screen__content__item {
    height: auto; } }

.start-screen--style-12 .start-screen__content__item {
  min-height: 600px;
  height: 85vh; }

.start-screen--style-12 .img-shape:nth-of-type(1) {
  min-width: 520px;
  max-width: 40%;
  max-height: 90%;
  top: 55%;
  left: 50vw;
  transform: translateY(-50%); }

.start-screen--style-13 .img-shape:nth-of-type(1) {
  min-width: 520px;
  max-width: 50%;
  max-height: 95%;
  top: 0;
  right: 0; }

/* --------------------------------
   hero
-------------------------------- */
.hero {
  min-height: 50vh;
  padding-top: 210px;
  padding-bottom: 30px;
  background-color: #2368fb;
  color: #fff;
  z-index: 1 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }
  .hero__title {
    line-height: 1.1;
    color: inherit;
    text-align: center; }
  .hero .section-heading--center {
    font-size: 20px; }

.hero__title {
  font-weight: 500 !important; }

/* --------------------------------
   site logo
-------------------------------- */
.site-logo {
  display: inline-block; }
  .site-logo img {
    vertical-align: middle;
    max-width: 100%; }

/* --------------------------------
   buttons
-------------------------------- */
.dashboard-btn:hover {
  background-color: #2368fb !important;
  border-color: #2368fb !important; }

.dark-btn {
  background-color: #2a2c2f !important;
  color: #fff !important;
  border: none !important; }

.dark-btn:hover {
  background-color: #343a3f !important; }

.is-sticky .dashboard-btn {
  background-color: #2368fb !important;
  border-color: #2368fb !important;
  color: #fff !important; }

.is-sticky .dashboard-btn:hover {
  background-color: #333 !important;
  border-color: #333 !important; }

.custom-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 28px;
  padding-right: 28px;
  line-height: 1;
  font-size: 1.6rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  text-align: center !important;
  text-decoration: none !important;
  text-shadow: none !important;
  letter-spacing: 0;
  border: 3px solid;
  border-radius: 30px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  z-index: 0;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, color 0.25s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-btn {
      transition: none; } }
  .custom-btn:before {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border-radius: inherit;
    transition: opacity 0.25s ease-in-out;
    z-index: -1; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-btn:before {
        transition: none; } }
  .custom-btn--medium {
    min-width: 155px;
    min-height: 54px;
    padding-top: 17px;
    padding-bottom: 17px; }
  .custom-btn--big {
    min-width: 180px;
    min-height: 65px;
    padding-top: 22px;
    padding-bottom: 22px; }
  .custom-btn.custom-btn--style-1 {
    color: #fff; }
    .custom-btn.custom-btn--style-1:before {
      content: "";
      opacity: 1;
      background: -moz-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, #6b5392), color-stop(18%, #6b5392), color-stop(60%, #1165b2), color-stop(100%, #00a4d4));
      background: -webkit-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
      background: -o-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
      background: -ms-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
      background: linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%); }
    .custom-btn.custom-btn--style-1:hover, .custom-btn.custom-btn--style-1:focus {
      background-color: #2d3a49;
      border-color: #2d3a49; }
      .custom-btn.custom-btn--style-1:hover:before, .custom-btn.custom-btn--style-1:focus:before {
        opacity: 0; }
  .custom-btn.custom-btn--style-2 {
    background-color: #2368fb;
    border-color: #2368fb;
    color: #fff; }
    .custom-btn.custom-btn--style-2:before {
      content: "";
      opacity: 0;
      background-color: #2368fb;
      border-color: #2368fb;
      color: #fff; }
    .custom-btn.custom-btn--style-2:hover, .custom-btn.custom-btn--style-2:focus {
      color: #fff; }
      .custom-btn.custom-btn--style-2:hover:before, .custom-btn.custom-btn--style-2:focus:before {
        opacity: 1; }
  .custom-btn.custom-btn--style-3 {
    background-color: #fff;
    border-color: #fff;
    color: #2368fb; }
    .custom-btn.custom-btn--style-3:before {
      content: "";
      opacity: 0;
      background-color: #fff;
      border-color: #fff;
      color: #2368fb; }
    .custom-btn.custom-btn--style-3:hover, .custom-btn.custom-btn--style-3:focus {
      color: #fff; }
      .custom-btn.custom-btn--style-3:hover:before, .custom-btn.custom-btn--style-3:focus:before {
        opacity: 1; }
  .custom-btn.custom-btn--style-4 {
    background-color: #fff;
    border-color: #fff;
    color: #333; }
    .custom-btn.custom-btn--style-4:hover, .custom-btn.custom-btn--style-4:focus {
      background-color: #2d3a49;
      border-color: #2d3a49;
      color: #fff; }
  .custom-btn.custom-btn--style-5 {
    background-color: #30e3ca;
    border-color: #30e3ca;
    color: #fff; }
    .custom-btn.custom-btn--style-5:hover, .custom-btn.custom-btn--style-5:focus {
      background-color: #47f2da;
      border-color: #47f2da; }
  .custom-btn.wide {
    width: 100%; }

/* --------------------------------
   form
-------------------------------- */
form {
  position: relative; }
  form .input-wrp {
    position: relative;
    display: block;
    width: 100%;
    line-height: 1;
    margin-bottom: 20px; }
  form .textfield {
    display: block;
    width: 100%;
    border: 2px solid;
    line-height: 1.2;
    font-size: 1.6rem;
    appearance: none;
    outline: none;
    padding: 15px 30px;
    box-shadow: none;
    border-radius: 30px;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      form .textfield {
        transition: none; } }
    form .textfield::-webkit-input-placeholder {
      color: #ccc;
      transition: color 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        form .textfield::-webkit-input-placeholder {
          transition: none; } }
    form .textfield::-moz-placeholder {
      color: #ccc;
      transition: color 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        form .textfield::-moz-placeholder {
          transition: none; } }
    form .textfield:-moz-placeholder {
      color: #ccc;
      transition: color 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        form .textfield:-moz-placeholder {
          transition: none; } }
    form .textfield:-ms-input-placeholder {
      color: #ccc;
      transition: color 0.3s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        form .textfield:-ms-input-placeholder {
          transition: none; } }
    form .textfield--light {
      background-color: #fff;
      border-color: #fff;
      color: #b1b1b1; }
      form .textfield--light:focus, form .textfield--light.focus {
        background-color: #fff;
        border-color: #2a2c2f !important; }
    form .textfield--grey {
      background-color: rgba(255, 255, 255, 0.2);
      border-color: #2a2c2f;
      color: #1d2124; }
      form .textfield--grey:focus, form .textfield--grey.focus {
        background-color: #fff;
        border-color: #2a2c2f; }
    form .textfield--dark {
      background-color: rgba(0, 0, 0, 0.2);
      border-color: rgba(0, 0, 0, 0);
      color: #fff; }
      form .textfield--dark:focus, form .textfield--dark.focus {
        border-color: #2a2c2f !important; }
    form .textfield.error {
      border-color: #2368fb !important; }
  form input.textfield {
    height: 56px; }
  form textarea {
    resize: vertical;
    min-height: 150px;
    height: 100%; }
  form button[type=submit] {
    cursor: pointer;
    box-shadow: none;
    outline: none;
    margin-top: 10px; }

.form--horizontal button[type=submit] {
  margin-top: 0; }

@media (min-width: 576px) {
  .form--horizontal .input-wrp {
    width: auto;
    margin: 0;
    margin-right: -50px; } }

label {
  cursor: pointer; }

.checkbox {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  line-height: 1.5;
  padding-left: 35px; }
  .checkbox input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    clip: rect(2px, 2px, 2px, 2px); }
    .checkbox input[type="checkbox"]:checked ~ i:before {
      transform: scale(1); }
    .checkbox input[type="checkbox"]:checked ~ span a {
      color: #2368fb; }
  .checkbox i {
    position: relative;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: -35px;
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    transition: background-color 0.25s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .checkbox i {
        transition: none; } }
    .checkbox i:before {
      content: '\2713';
      display: block;
      line-height: 17px;
      transform: scale(0);
      color: #2368fb;
      transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1); }
      @media screen and (prefers-reduced-motion: reduce) {
        .checkbox i:before {
          transition: none; } }
    .checkbox i:last-child {
      margin-right: 0; }

.help-block li {
  padding-left: 32px; }

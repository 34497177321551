/* --------------------------------
   hero
-------------------------------- */

.hero {
  min-height: 50vh;
  padding-top: 210px;
  padding-bottom: 30px;
  //@extend %bg-cover;
  background-color: $primary-color;
  color: $white;
  z-index: 1 !important;
  @include userSelect(none);

  &__title {
    line-height: 1.1;
    color: inherit;
    text-align: center;
  }

  .section-heading--center {
    font-size: 20px;
  }
}

.hero__title {
  font-weight: 500 !important;
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}

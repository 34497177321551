h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.2;
  font-weight: 700;
  font-family: $fontFamily-secondary;
  color: $branding-black-dark;
  margin-top: 20px;
  margin-bottom: 20px;
  @include transition(color 0.3s ease-in-out);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  span {
    font-weight: 300;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  letter-spacing: -0.05em;
}

h1, .h1 {
  font-size: rem-calc(20px);

  @include media-breakpoint-up(sm) {
    font-size: rem-calc(35px);
  }

  @include media-breakpoint-up(md) {
    font-size: rem-calc(50px);
  }
}

h2, .h2 {
  font-size: rem-calc(35px);

  @include media-breakpoint-up(sm) {
    font-size: rem-calc(40px);
  }
}

h3, .h3 {
  font-size: rem-calc(30px);
}

h4, .h4 {
  font-size: rem-calc(22px);
}

h5, .h5 {
  font-size: rem-calc(16px);
}

h6, .h6 {
  font-size: rem-calc(13px);
  text-transform: uppercase;
}

main {
  @include textSelection {
    background-color: $primary-color;
    color: $white;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

a {
  color: $primary-color;
  text-decoration: underline;
  outline: none;
  @include transition(color 0.3s ease-in-out);

  &:hover,
  &:focus {
    color: $primary-color;
    text-decoration: none;
  }
}

.section-heading {
  // margin-bottom: 60px;
  line-height: 1.4;
  font-size: rem-calc(19px);
  color: $branding-black-mid;

  .__title {
    &:first-child {
      margin-top: -0.2em;
    }
  }

  .__subtitle {
    font-family: $fontFamily-primary;
    color: $primary-color;
  }

  &--left {
    text-align: left;
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--white {
    color: $white;

    .__title,
    .__subtitle {
      color: inherit;
    }
  }
}

.text-grading {
  color: $primary-color;
}

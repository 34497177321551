@import 'utils/variables';
@import 'utils/extends';
@import 'utils/filters';
@import 'utils/functions';
@import 'utils/media-queries';
@import 'utils/mixins';
@import 'utils/angled-edges';

* {
  padding: 0;
  margin: 0;

  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

.header-progress-bar {
  display: none!important;
}


.ico-checked-center {
  float: none !important;
  margin-right: 15px !important;
}

.item-title {
  color: #fff;

  .small {
    color: #ccc;
    font-size: 1.4rem;
    font-weight: 400;
  }
}

.submenu {
  a:hover {
    color: #fff !important;
  }
}


.configurator {
  background: #1d2124;
  border-radius: 25px;
  padding: 40px;
  color: #fff;

  .configurator-pricing {
    font-size: 3rem !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;

    span {
      font-size: 27px !important;

      letter-spacing: normal !important;
    }
  }

  .accordion-item-first {
    border-top: none !important;
  }

  .accordion-content__inner {
   p {
     margin-bottom: 20px;
   }
  }

  .btn {
    font-size: 1.8rem !important;
    text-transform: none !important;
  }

  .accordion-toggler i {
    left: 0;
    right: unset;
  }

  @media (min-width: 576px) {
    .accordion-toggler {
      padding: 30px 0px 30px 45px !important;
    }
  }
}

.is-sticky {
  .text-shadow {
    text-shadow: none !important;
  }
}

.is-expanded {
  .text-shadow {
    text-shadow: none !important;
  }
}

.title-tag {
  font-size: 20px;
  font-weight: 700;
  color: #14212b;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  line-height: 1.6 !important;
}

.title-tag-lr {
  font-size: 20px;
  font-weight: 700;
  color: #14212b;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  padding-right: 45px;
  line-height: 1.6 !important;
}

.font-small {
  font-size: 12px;
}

.__title {
  margin-top: 0px !important;
}

.title-tag::before {
  display: block;
  position: absolute;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: #14212b;
  content: "";
  top: 16px;
  transform: translateY(-50%);
}

.title-tag-white::before {
  background-color: #fff !important;
}

.title-tag-white::after {
  background-color: #fff !important;
}

.nav-link-small {
  padding: 0px !important;
}

.nav-link-small button {
  padding-top: 8px !important;
  //width: 65% !important;
  padding-bottom: 8px !important;
  min-height: 45px !important;
}

.title-tag-lr::before {
  display: block;
  position: absolute;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: #14212b;
  content: "";
  top: 16px;
  transform: translateY(-50%);
}

 .title-tag-lr::after {
   display: block;
   left: auto;
   right: 0;
   position: absolute;
   width: 30px;
   height: 3px;
   background-color: #14212b;
   content: "";
   top: 50%;
   transform: translateY(-50%);
 }

.text-shadow {
  text-shadow: 3px 3px 7px black;
}

.customer-title {
  font-size: 25px;
}

#landing-page {
  #brands-small {
    padding-bottom: 0px !important;
  }
}

#start-screen {
  .nav-link {
    padding: 0px;
  }

  .title-span {
    //letter-spacing: 8px;
  }
}

.main-title {
  position: absolute;
  margin: 0;
  padding: 0;
  color: #f9f1e9;
  text-align: center;
  top: -16%;
  left: 0;
}

.slick-track {
  padding-top: 12px;
  padding-bottom: 12px;
}

.background-overlay {
  background: rgba(32, 33, 36, 0.66);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.img-rounded {
  border-radius: 15px;
}

.section-bg-light-dark {
  background-color: #f3f3f3;
}

.section-special {
  background-image: linear-gradient(#f3f3f3 50%, #fff 50%) !important;
  background-size: calc(1 * (100% / 2)) 100%;
}

.section-special .__inner::before {
  left: 0px;
  right: 0px;
  bottom: -15px;
  top: 20px;
}
.section-special .__inner::before {
   position: absolute;
   background-color: #14212b;
   z-index: -2;
   content: "";
   opacity: 0.07;
  border-radius: 20px;
 }

.section-special .__inner::after {
  left: -15px;
  right: -15px;
  bottom: -30px;
  top: 40px;
}
.section-special .__inner::after {
   position: absolute;
   background-color: #14212b;
   z-index: -2;
   content: "";
   opacity: 0.07;
  border-radius: 20px;
 }

.pricing-btn {
  color: #2a2c2f !important;
}

.pricing-btn:hover {
  color: #fff !important;
}

.start-screen__content-container {
  h2 {
    font-size: 60px;
  }
}

#large-header {
  height: 75vh !important;
}

.large-header {
  position: relative;
  width: 100%;
  background: #333;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

html {
  font-size: $fontSize-root;
  text-size-adjust: 100%;

  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

body {
  line-height: 1.6;
  font: {
    size: rem-calc($fontSize-base);
    family: -apple-system, BlinkMacSystemFont, $fontFamily-base;
    weight: 400;
  };
  color: $branding-black-mid;
  background-color: #fff;
}

@media (min-width: 1100px) {
  .easy-digitization {
    width: 30% !important;
  }
}

@media (min-width: 800px) {
  .easy-digitization {
    width: 40% !important;
  }
}

@media (min-width: 580px) {
  .easy-digitization {
    width: 50% !important;
  }
}

.easy-digitization {
  width: 60% !important;
}

ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
}

#career-offer {
  .__title {
    color: $branding-black-dark !important;
  }
}

.rounded-img {
  border-radius: 100%;
  border: 2px solid $branding-black-dark;
}

.font-size-16 {
  font-size: 1.6rem !important;
}

.container-start-screen {
  // max-width: 1500px !important;
  padding-top: 0 !important;
  vertical-align: middle;
  margin: auto;
}

@media (min-width: 1600px) {
  .container-start-screen {
    // max-width: 1500px !important;
 //   padding-top: 100px;
  }
}

@media (min-width: 1200px) {
  .container-start-screen {
   // padding-top: 150px;
  }
}

.non-flex {
  display: -webkit-box !important;
}

.align-top {
  vertical-align: top !important;
  display: inline-block !important;
}

.medium {
  width: 50%;
}

.mt-20 {
  margin-top: 20px;
}

// Required
@import "vendors/bootstrap-4/functions";
@import "vendors/bootstrap-4/variables";
@import "vendors/bootstrap-4/mixins";
// Optional
@import "vendors/bootstrap-4/grid";
@import "vendors/bootstrap-4/utilities/display";
@import "vendors/bootstrap-4/utilities/flex";
@import "vendors/bootstrap-4/images";

@import url(vendors/normalize.css);
@import url(vendors/fontello-codes.css);

@import 'vendors-extensions/fontello';

@import 'base/typography';

@import 'layout/header';
@import 'layout/start-screen';
@import 'layout/hero';

@import 'components/site_logo';
@import 'components/buttons';
@import 'components/forms';
